/**
 * Converts an ISO date string (e.g. `2023-12-25` or `2023-12-25T12:00:00`) to a 
 * plain date string in `DDMMYYYY` format (e.g. `25122023`)
 */
export const ISODateToPlainDate = (date: string) => {
    return date
        .split("T")[0]
        .split("-")
        .reverse()
        .join("")
}