import * as t from "io-ts";
import { array, option, record } from "fp-ts/lib";
import { DateTime } from "luxon";
import { pipe } from "fp-ts/lib/function";
import { formatMoney } from "accounting";

export const isObject = (input: unknown): input is Record<string, unknown> => Object.prototype.toString.call(input) === "[object Object]";

export const requireExhaustive = (a: never): never => { throw new Error(); }; // eslint-disable-line

export const typeValuesFromCodec = <T extends string>(a: Array<t.LiteralC<T>>): Array<T> =>
    array.map<t.LiteralC<T>, T>((type) => type.value)(a);

export const getNextDateMonToSat = (dateFormat: "YMD" | "DMY"): string => {
    const tomorrow: Date = new Date(Date.now() + 86400000);
    const nextWorkDay: string = (
        tomorrow.getDay() === 0
            ? new Date(Date.now() + 172800000)
            : tomorrow
    ).toISOString().substr(0, 10);
    const dd: string = nextWorkDay.substr(8, 2);
    const mm: string = nextWorkDay.substr(5, 2);
    const yyyy: string = nextWorkDay.substr(0, 4);
    let placeholder: string = `${dd}-${mm}-${yyyy}`;
    if (dateFormat === "YMD") {
        return placeholder = `${yyyy}-${mm}-${dd}`;
    }
    return placeholder;
};

export const getRandomInt = (min: number, max: number): number =>
    Math.floor(Math.random() * (max - min + 1)) + min;

export const capitalizeFirstLetter = (s: string) => s.charAt(0).toUpperCase() + s.slice(1);

export const snakeCaseToCopyText = (text: string): string => 
    capitalizeFirstLetter(
        text
            .replace(/([A-Z])/g, ' $1')
            .replace(/_/g, ' ')
            .trim()
    );

export const snakeCaseToCopyTextLower = (text: string): string => 
    text
    .replace(/([A-Z])/g, ' $1')
    .replace(/_/g, ' ')
    .trim()

export const removeText = (textToRemove: string) => 
    (text: string): string =>
        text.replace(textToRemove, "")

export const camelCaseToCopyText = (text: string): string => {
    const result = text.replace(/([A-Z])/g, " $1");
    return result.charAt(0).toUpperCase() + result.slice(1);
} 

export const arrayOfStringsToPipeString = (arrayOfStrings: Array<string>): string => arrayOfStrings.join("|");

export const moveIndexFromToBeforeInArray = <T>(from: number, before: number, arr: Array<T>): Array<T> => {
    const fromIndex = from < 0 ? 0 : from > arr.length ? arr.length : from;
    const beforeIndex = before < 0 ? 0 : before > arr.length + 1 ? arr.length + 1 : before;
    if (from === before) {
        return [...arr];
    }
    let newArray: Array<T> = [];
    for (let index = 0; index < arr.length; index++) {
        const element = arr[index];
        if(fromIndex !== index) {
            if (index === beforeIndex) {
                newArray.push(arr[fromIndex])
            }
            newArray.push(element)
        }
    }
    if (beforeIndex + 1 > arr.length) {
        newArray.push(arr[fromIndex]);
    }
    return newArray;
}
export const normaliseValueToArray = <A extends string>(value: A | Array<A>): Array<A> =>
    typeof value === "string" ? [value] : value;

export const isInvalidDate = (date: string | null) =>
    date === null 
    || DateTime.fromISO(date as string).toMillis() < DateTime.local().toMillis()

export const pluralSingleText = <T extends string>(single: T, plural: T) => (count: number): T =>
    (count === 1 || count === -1) ? single : plural

export const queryStringToTsVectorCompatibleString = (value: string): string => pipe(
    (typeof value === "string" ? value : "").split(" "),
    array.map((s) => s.replace(/[^a-zA-Z0-9+]/g, "")),
    array.map((s) => s.trim()),
    array.filterMap((s) => s !== "" ? option.some(`${s}:*`) : option.none),
    (a) => a.join(" & "),
);

export const isEmpty = (value: Array<unknown> | string | boolean | Record<string, unknown> | null | undefined): boolean => {
    
    if (value === null || value === undefined) {
        return true;
    }
    
    if (value.constructor === Array || typeof value === "string") {
        return value.length === 0;
    }
    
    return record.isEmpty(value as Record<string, unknown>);
}

export const isFilled = (value: Array<unknown> | string | Record<string, unknown> | null | undefined): boolean =>
    !isEmpty(value)
;

export const penceToCopyText = (pence: number, opts?: {keepTrailingZeros?: boolean}): string => {
    const formatted = formatMoney(pence/100, "£", 2);
    const formatSplit = formatted.split(".");
    
    if (formatSplit[1] === "00" && opts?.keepTrailingZeros !== true) {
        return formatSplit[0];
    }
    return formatted;
}

/**
 * The createIndexMap helper function is useful when you have an array of data and you want to
 * be able to look up items by one of their (potentially nested) properties without having to 
 * resort to repeatedly using "array.filter" or "array.find"
 * 
 * E.g.
 * const array1 = [{key: "id1"}, {key: "id2"}];
 * const indexMap = createIndexMap(array1, (item) => item.key);
 * 
 * const itemLookedUpByKey = indexMap["key2"]; // This yields {key: "id2"}
 */
export const createIndexMap = <T>(sourceArray: Array<T>, getIndexValue: (p: T) => string): Record<string, T> =>
    pipe(
        sourceArray,
        array.reduce({}, (acc, payload) => {
            acc[getIndexValue(payload)] = payload;
            return acc;
        })
    );

/**
 * This helper function is similar to @see createIndexMap but instead of only returning one value
 * per key it will return an array of values per key. 
 */
export const createIndexMapOfSharedIds = <T>(sourceArray: Array<T>, getIndexValue: (p: T) => string): Record<string, T[]> =>
    pipe(
        sourceArray,
        array.reduce({}, (acc, payload) => {
            const index = getIndexValue(payload)
            acc[index] = [...(acc[index] ?? []), payload];
            return acc;
        })
    );

