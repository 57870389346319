import React, { useState } from "react";
import { DateTime } from "luxon";
import { TClientCaseIdentityVerificationUserForm } from "../../../../../../domain/codecs/form/ClientCaseForm";
import { UserLegalNamesToFullLegalName } from "../../../../../../domain/codecs/overloads/UserLegalNamesToFullLegalName";
import { UserLegalCorrespondenceAddressToCopyText } from "../../../../../../domain/codecs/overloads/Address";
import { FrontSpacing } from "../../../../models/FrontSpacing";
import { InlineFlex } from "../../../BuildingBlocks/InlineFlex";
import { SpacingColumn } from "../../../BuildingBlocks/SpacingColumn";
import { WeightBold } from "../../../WeightBold/WeightBold";
import { FrontAddressSearchInput } from "../../Simple/FrontAddressSearchInput/FrontAddressSearchInput";
import { FrontCondensedQuestionWrap } from "../../Simple/FrontCondensedQuestionWrap/FrontCondensedQuestionWrap";
import { FrontFormPrimaryButton } from "../../Simple/FrontFormPrimaryButton/FrontFormPrimaryButton";
import { FrontFormSectionTitle } from "../../Simple/FrontFormSectionTitle/FrontFormSectionTitle";
import { FrontHelpBubble } from "../../Simple/FrontHelpBubble/FrontHelpBubble";
import { FrontLegalNameForm } from "../FrontLegalNameForm/FrontLegalNameForm";
import { FrontOutsidePopupBasic } from "../FrontOutsidePopupBasic/FrontOutsidePopupBasic";
import { isFilled } from "../../../../../../shared/src/util";
import { FrontDateOfBirthInput } from "../../Simple/FrontDateOfBirthInput/FrontDateOfBirthInput";
import FrontInputLabelAndErrorWrapComponent from "../../Simple/FrontInputLabelAndErrorWrapComponent/FrontInputLabelAndErrorWrapComponent";
import { onChangeForm } from "../../../../../../shared/src/codecs/types/form";
import { useLinearStepProgression } from "../../../../hooks/useLinearStepProgression";
import FrontParagraphSmall from "../../Simple/FrontParagraphSmall/FrontParagraphSmall";
import { SpacingRow } from "../../../BuildingBlocks/SpacingRow";
import { MediaPropSwitch } from "../../../BuildingBlocks/MediaPropSwitch";
import { FrontVimeoEmbed } from "../../Simple/FrontVimeoEmbed/FrontVimeoEmbed";
import { MediaCompSwitch } from "../../../BuildingBlocks/MediaCompSwitch";

type TIdentityVerificationPopupProps = {
    isOpen: boolean;
    onClose: () => void;
    form: TClientCaseIdentityVerificationUserForm;
    onChange: (form: TClientCaseIdentityVerificationUserForm) => void;
    onAutoCompleteAddress: (form: TClientCaseIdentityVerificationUserForm) => void;
    onSubmit: (form: TClientCaseIdentityVerificationUserForm) => void;
    onBeginPhotoVerification: (form: TClientCaseIdentityVerificationUserForm) => void;
};

type TSteps =
    "name_and_birth"
    | "address"
    | "photo"
;

export const FrontIdentityVerificationPopup = (props: React.PropsWithChildren<TIdentityVerificationPopupProps>): JSX.Element => { 

    const isNameAndBirthFilled = (): boolean => 
        props.form.edited.legal_first_legal_name.length > 0 &&
        props.form.edited.legal_last_legal_name.length > 0 &&
        isFilled(props.form.edited.legal_date_of_birth)
    ;

    const isAddressFilled = (): boolean => 
        isFilled(props.form.edited.legal_correspondence_postcode)
        && (isFilled(props.form.edited.legal_correspondence_building_name) || isFilled(props.form.edited.legal_correspondence_building_number))
        && isFilled(props.form.edited.legal_correspondence_city_town)
        && isFilled(props.form.edited.legal_correspondence_street_name)
    ;

    const stepState = useLinearStepProgression<TSteps>([
        ["name_and_birth", isNameAndBirthFilled()],
        ["address", isAddressFilled()],
        ["photo", false],
    ]);
    const [isNameOpen, setIsNameOpen] = useState(!isNameAndBirthFilled());
    const [isAddressOpen, setIsAddressOpen] = useState(!isAddressFilled());

    const onSubmitName = () => {
        props.onSubmit(props.form);
        stepState.setStepAsComplete("name_and_birth");
        setIsNameOpen(false);
    }
    
    const onSubmitAddress = () => {
        props.onSubmit(props.form);
        stepState.setStepAsComplete("address");
        setIsAddressOpen(false);
    }
    
    const onAutoCompleteAddress = () => {
        props.onAutoCompleteAddress(props.form);
    }

    const getDateOfBirthPrettyText = (): string => 
        props.form.edited.legal_date_of_birth ? 
            DateTime.fromISO(props.form.edited.legal_date_of_birth).setZone("Europe/London").toFormat("dd/MM/yyyy")
            : ""
    ;

    const onChange = onChangeForm(props.form, props.onChange);

    return (
        <FrontOutsidePopupBasic
            isOpen={props.isOpen}
            title="Identity verification"
            onClose={props.onClose}
        >
            <SpacingColumn spacing={FrontSpacing.LARGE_2}>
                {/* SECTION - LEGAL NAME */}
                <FrontCondensedQuestionWrap
                    isOpen={isNameOpen}
                    setIsOpen={setIsNameOpen}
                    label={`Your name and date of birth`}
                    summary={`${UserLegalNamesToFullLegalName(props.form.edited)}, ${getDateOfBirthPrettyText()}`}
                >
                    <SpacingColumn spacing={FrontSpacing.MEDIUM_1}>
                        <FrontParagraphSmall>
                            What is your full legal name and date of birth <WeightBold>as it is written on your ID?</WeightBold>
                        </FrontParagraphSmall>

                        <FrontLegalNameForm
                            form={props.form}
                            onChange={(nameForm) => props.onChange({
                                ...props.form,
                                edited: {
                                    ...props.form.edited,
                                    ...nameForm.edited
                                },
                            })}
                        />

                        <MediaCompSwitch
                            MobileComponent={SpacingColumn}
                            mobileProps={{ spacing: FrontSpacing.MEDIUM_2}}
                            DesktopComponent={SpacingRow}
                            desktopProps={{ spacing: FrontSpacing.MEDIUM_1, childSize: "1fr 120px", alignItems: "flex-end" }}
                            breakpoint="600"
                        >
                            <FrontInputLabelAndErrorWrapComponent label="Date of birth (day, month, year)">
                                <FrontDateOfBirthInput
                                    value={props.form.edited.legal_date_of_birth}
                                    onChange={onChange("legal_date_of_birth")}
                                />
                            </FrontInputLabelAndErrorWrapComponent>
                            
                            <InlineFlex>
                                <FrontFormPrimaryButton
                                    label="Save"
                                    icon="done"
                                    isDisabled={!isNameAndBirthFilled()}
                                    onClick={onSubmitName}
                                />
                            </InlineFlex>
                        </MediaCompSwitch>

                    </SpacingColumn>
                </FrontCondensedQuestionWrap>
                
                {/* SECTION - ADDRESS */}
                {stepState.areAllStepsBeforeThisOneComplete("address") &&
                    <FrontCondensedQuestionWrap
                        isOpen={isAddressOpen}
                        setIsOpen={setIsAddressOpen}
                        label={`Your current address`}
                        summary={UserLegalCorrespondenceAddressToCopyText(props.form.edited)}
                    >
                        <SpacingColumn spacing={FrontSpacing.MEDIUM_1}>
                            <FrontFormSectionTitle>
                                Address
                            </FrontFormSectionTitle>

                            <SpacingColumn spacing={FrontSpacing.MEDIUM_1}>
                                <FrontParagraphSmall>
                                    Please enter the details of your current <WeightBold>living address</WeightBold>.
                                </FrontParagraphSmall>

                                <FrontAddressSearchInput
                                    form={props.form}
                                    onChange={(addressForm) => props.onChange({
                                        ...props.form,
                                        edited: {
                                            ...props.form.edited,
                                            ...addressForm.edited
                                        },
                                    })}
                                    onAutoComplete={onAutoCompleteAddress}
                                    submit={{
                                        label: "Save",
                                        icon: "done",
                                        isDisabled: !isAddressFilled(),
                                        onClick: onSubmitAddress
                                    }}
                                />
                            </SpacingColumn>
                        </SpacingColumn>
                    </FrontCondensedQuestionWrap>
                }

                {/* SECTION - PHOTO VERIFICATION */}
                {stepState.areAllStepsBeforeThisOneComplete("photo") &&
                    <SpacingColumn spacing={FrontSpacing.MEDIUM_2}>
                        <FrontFormSectionTitle>
                            Photo Verification
                        </FrontFormSectionTitle>
                        
                        <SpacingColumn spacing={FrontSpacing.MEDIUM_1}>
                            <SpacingColumn spacing={FrontSpacing.SMALL_2}>
                                <FrontParagraphSmall>
                                    This is the <WeightBold>final step</WeightBold> in your identity verification.
                                </FrontParagraphSmall>
                                <FrontParagraphSmall>
                                    Please watch the <WeightBold>video</WeightBold> below for a step by step guide on how to complete it.
                                </FrontParagraphSmall>
                                <FrontParagraphSmall>
                                    Then click the button below to begin.
                                </FrontParagraphSmall>
                            </SpacingColumn>

                            <FrontVimeoEmbed
                                title="Video Verification Guide"
                                src="https://player.vimeo.com/video/683810597?h=5c35ad5338&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                            />

                            <FrontHelpBubble
                                type="info"
                                label="Don’t have a camera on this device?"
                                paragraph={<>
                                    You can complete this step using your <WeightBold>mobile, tablet</WeightBold> or <WeightBold>laptop</WeightBold> camera.
                                    Simply switch to them and navigate back to this page.
                                </>}
                            />

                            <InlineFlex>
                                <FrontFormPrimaryButton
                                    label="Begin Photo Verification"
                                    icon="camera"
                                    onClick={() => props.onBeginPhotoVerification(props.form)}
                                    isDisabled={props.form.children.create_id_verification_link.status === "submitting"}
                                />
                            </InlineFlex>
                        </SpacingColumn>
                    </SpacingColumn>
                }
            </SpacingColumn>
        </FrontOutsidePopupBasic>
    );
};
