import React, { useEffect, useState } from "react";
import { array, identity } from "fp-ts";
import { pipe } from "fp-ts/lib/pipeable";
import { mergeOnTop } from "../../../shared/src/utilsByDomain/array/mergeOnTop";
import { allMatchPredicate } from "../../../shared/src/utilsByDomain/array/allMatchPredicate";
import { isPlain } from "../../../shared/src/utilsByDomain/function/isPlain";

type TUseSplitInputProps = { 
    value: string;
    preFormat?: (value: string) => string;
    filter?: (value: string) => boolean;
    shouldMoveToNextInput?: (key: string) => boolean;
    chunkConfig: Array<TChunkConfig>;
    onChange: (value: string, splitValue: Array<string>) => void;
    onChangeToInvalid?: () => void;
};

type TChunkConfig = {
    max: number;
    min?: number;
    validation: (value: string, chunkValues: Array<string>) => boolean;
    filter?: (value: string) => boolean;
    onChangeFormat?: (value: string) => string;
    shouldUpdateLocalState?: (incomingValue: string, currentValue: string) => boolean;
}

export type TChunkState = {
    ref: React.RefObject<HTMLInputElement>;
    maxLength: number;
    value: string;
    setValue: (newValue: string) => void;
    onChangeFormat: (value: string) => string;
    shouldUpdateLocalState: (incomingValue: string, currentValue: string) => boolean;
    isFilled: () => boolean;
    isHighlighted: () => boolean;
    hasError: () => boolean;
    handleKeyUp: (event: React.KeyboardEvent<HTMLInputElement>) => void;
};

type TUseSplitInputState = Array<TChunkState>;

/**
 * A hook that manages the state for a text input which needs to be split into **multiple** input components.
 * (eg: security code, date input, card long number, etc.)
 * 
 * Takes a config, and spits out an array of chunk states, which are determined by the config.
 * 
 * You can then use those chunk states to hookup individual inputs, whose collective values
 * result in a single value string update.
 * 
 * #### Conceptual explanation
 * This hook behaves on a concept of 2 states:
 * 1. The Global state: (the value, and onChange callbacks that update the value)
 * 2. The Local state: each chunk has a **local input value** state.
 * 
 * Split inputs have this problem where they need the global and local states to be **conditionally** coupled.
 * 
 * Rather than having them always coupled.
 * 
 * Because of this you will find that the props have options in the chunkConfig and outside it to allow you to choose:
 * 1. *When* you want the **local** chunk value to be **updated by the global** value, and
 * 2. *How & When* you want the **global** value to be **updated by the local** value.
 * 
 * This is all achieved through filters and formatters at various stages of the components input life cycle.
 * 
 * **Lifecycle**
 * STAGE I: Ingest global value.
 * 1. Global `value` changes
 * 2. `preFormat` runs
 * 3. `filter` runs (terminate here if filter returns false)
 * STAGE II: Update split input values.
 * 1. `value` is split into substrings that match the `max` size of each chunk.
 * 2. Each `chunkState` updated with it's value substring IF `shouldUpdateLocalState` is true.
 * STAGE III: Updating global state
 * 1. Split inputs have values typed into them.
 * 2. For each keypress a `chunk.filter` is run, if true local chunk state is updated.
 * 3. `chunk.validation` is run every time the local value is updated to check if it has an error.
 * 4. On each keypress you check if all local chunk values are filled & pass validation
 *  * If true we call `onChange`
 *  * If false we call `onChangeInvalid` if it's present.
 * 5. This then triggers the global value change which triggers the first stage.
 * 
 * @example 
 * // Assume we are creating a input for card expiry dates.
 * // * The input requires a 4 digit string, which is the month and year (eg: 0225)
 * // * The component has two text inputs in it, one for the month one for the year.
 * 
 * const [expiryMonthState, expiryYearState] = useSplitInput({
 *     value: cardExpiryValue,
 * 
 *     // (optional) This is run when the `value` changes from the outside ie: the global state.
 *     // Useful for when the global value is an inconvenient format to chunk, and you need to
 *     // prepare it for chunking.
 *     // eg: we want an ISO date (2023-12-25T12:00:00) to be 25122023 so that we can chunk it easily
 *     // by day, month and year (without the extra characters complicating things).
 *     preFormat?: formatDateWhenItChanges, 
 * 
 *     // (optional) Asserts weather the split inputs states should be 
 *     // updated with this value.
 *     // eg: This is useful for when you may want to keep the local state 
 *     // intact visually, whilst at the same time updating the underlying value.
 *     filter?: (value) => value.length > 0,
 * 
 *     // The configuration array which tells the hook how to split 
 *     // the string `value` into individual input states called "chunks"
 *     chunkConfig: [
 *         {
 *             // the true size of the chunk split (it is the first 2 characters of the string)
 *             // this defines the segmentation of the "value".
 *             max: 2,  
 *             // (optional) the minimum amount of characters needed for 
 *             // the chunk to be considered "filled in"
 *             min?: 1, 
 *             // Runs after a chunk is considered "filled in".
 *             // It is responsible for telling if a chunk has errors.
 *             // Every chunks value has to pass validation for onChange to trigger. 
 *             validation: isDayInputValid,
 *             // (optional) Decides if the chunk state should be updated with the input value.
 *             // Good for filtering out unwanted inputs like letters for a numeric input.
 *             filter?: isANumericString,
 *             // (optional) A function that formats the new chunk value for the onChange callback.
 *             // Useful for when you want the local chunk state to look one way but you
 *             // need the underlying value given to the global state to be specifically formatted.
 *             // eg: you allow "1" for a day number, but it has to be a two digit number for the 
 *             // global state.
 *             onChangeFormat?: addLeadingZeroToStringInt,
 *             // (optional) Checks weather the local chunk state needs to have the
 *             // new global state "value" propagated to it.
 *             // eg: if typed 2 in the day input, and 25 in the year input
 *             // a onChange event would be called and the value would be formatted to "0225"
 *             // if you did not want you 2 in the day input to be reformatted to 02 you should
 *             // create a filter here for that.
 *             shouldUpdateLocalState?: shouldUpdateDatePart
 *         },
 *         { 
 *             max: 2,
 *             validation: isARealTwoDigitYear,
 *             filter: isNumericString,
 *             onChangeFormat: formatYear,
 *             shouldUpdateLocalState: shouldUpdateDatePart
 *         },
 *     ],
 * 
 *     // Called when: 1) all chunks are considered "filled in", and 2) all chunks pass their validation.
 *     onChange: (value) => {
 *         props.onUpdateCardExpiry(value)
 *     },
 *     // Called when any of the above conditions are false
 *     onChangeToInvalid: () => {
 *         props.onUpdateCardExpiry(null);
 *     }
 * })
 * 
 * // What's inside a chunkState.
 * // Note: All these props go into a <Input>s "props" or it's direct "className"
 * const {
 *     // Input - ref (used for keyboard behaviour and focusing to work, must be hooked up)
 *     ref: React.RefObject<HTMLInputElement>;
 *     // Input - maxLength
 *     maxLength: number;
 *     // Input - value
 *     value: string;
 *     // Input - onChange (use "event.target.value" init).
 *     setValue: (newValue: string) => void;
 *     // Input - className (to handle styles that highlight an input)
 *     isHighlighted: () => boolean;
 *     // Input - className (to handle styles that show the input has an error)
 *     hasError: () => boolean;
 *     // Input - onKeyUp (must be hooked up for keyboard behaviour to work)
 *     handleKeyUp: (event: React.KeyboardEvent<HTMLInputElement>) => void;
 *     
 *     // INTERNALLY USED (but may come in handy on the outside)
 *     // Is it considered "filled in", ie: meets the min size criteria.
 *     isFilled: () => boolean;
 *     // Same as the chunk config function
 *     onChangeFormat: (value: string) => string;
 *     // Same as the chunk config function
 *     shouldUpdateLocalState: (incomingValue: string, currentValue: string) => boolean;
 * } = expiryMonthState;
 * 
 * // Example hookup
 * <input
 *     ref={ref}
 *     className={`
 *         input
 *         input--${hasError() ? "error" : "no-error"}
 *         input--${isHighlighted() ? "highlighted" : "not-highlighted"}
 *     `}
 *     
 *     type={"text"}
 *     value={value}
 *     onChange={(event) => setValue(event.target.value)}
 *     onKeyUp={handleKeyUp}
 *     maxLength={maxLength}
 * />
 */
export const useSplitInputV2 = (props: TUseSplitInputProps): TUseSplitInputState => {

    const filter = props.filter || booleanFuncDud;
    const preFormat = props.preFormat || identity.flatten;
    const chunkValueStates = props.chunkConfig.map(() => useState<string>(""));
    const chunkRefs = props.chunkConfig.map(() => React.createRef<HTMLInputElement>());
    const chunkCaretCache = props.chunkConfig.map(() => useState<null | number>(null));
    const chunkCaretEndCache = props.chunkConfig.map(() => useState<null | number>(null));
    const shouldMoveToNextInput = props.shouldMoveToNextInput || (() => false);

    const chunkStates: Array<TChunkState> = props.chunkConfig.map((chunk, index) => {
        
        let [value, updateValue] = chunkValueStates[index];    
        let ref = chunkRefs[index];
        let filter = chunk.filter || booleanFuncDud;
        let onChangeFormat = chunk.onChangeFormat || identity.flatten;
        let min = chunk.min || chunk.max;
        let max = chunk.max;
        let shouldUpdateLocalState = chunk.shouldUpdateLocalState || booleanFuncDud;
        
        let setValue = (newValue: string) => {
            if (filter(newValue)) {
                updateValue(newValue);
            }
        };
        let isFilled = () => value.length >= min;
        let isHighlighted = () => (
            value.length > 0 
            && value.length < min
        );
        let hasError = () => (
            isFilled()
            && !chunk.validation(value, chunkValueStates.map(([value]) => value))
        );
        let handleKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
            
            let [prevCaretPosition, setPrevCaretPosition] = chunkCaretCache[index];
            let [prevCaretEndPosition, setPrevCaretEndPosition] = chunkCaretEndCache[index];
            
            let caretPosition = event.currentTarget?.selectionStart || 0;
            let caretEndPosition = event.currentTarget?.selectionEnd || 0;
            let hasCaretPositionBeenTheSame = prevCaretPosition === caretPosition && prevCaretEndPosition === caretEndPosition;

            setPrevCaretPosition(caretPosition);
            setPrevCaretEndPosition(caretEndPosition);
            
            let isCaretAtStart = caretPosition === 0;
            let isCaretAtEnd = caretPosition === value.length;
            let isLastInput = index === props.chunkConfig.length-1;
            let isFirstInput = index === 0;
            let isFilled = value.length === max;
            let isEventKeySpace = event.key === " ";
            let doesKeyPassFilter = event.key === "Unidentified" ? true : filter(event.key);

            let goNextInput = () => {
                let newIndex = index+1;
                let element = chunkRefs[newIndex];
                let position = 0;

                element.current?.focus();
                element.current?.setSelectionRange(position, position);
                chunkCaretCache[newIndex][1](position);
            }
            let goPrevInput = () => {
                let element = chunkRefs[index-1];
                let position = element.current?.value.length || 0;

                element.current?.focus();
                element.current?.setSelectionRange(position, position);
            }

            if (event.key === "Backspace" && isCaretAtStart && !isFirstInput && hasCaretPositionBeenTheSame) {
                goPrevInput();
            }
            else if (event.key === "ArrowRight" && isCaretAtEnd && !isLastInput && hasCaretPositionBeenTheSame) {
                goNextInput();
            }
            else if (event.key === "ArrowLeft" && isCaretAtStart && !isFirstInput && hasCaretPositionBeenTheSame) {
                goPrevInput();
            }
            else if (isEventKeySpace && isCaretAtEnd && !isLastInput) {
                goNextInput();
            }
            else if (shouldMoveToNextInput(event.key) && isCaretAtEnd && !isLastInput) {
                goNextInput();
            }
            else if (doesKeyPassFilter && isCaretAtEnd && isFilled && !isLastInput) {
                goNextInput();
            }
        }

        return {
            ref,
            maxLength: max,
            value,
            setValue,
            onChangeFormat,
            shouldUpdateLocalState,
            isFilled,
            isHighlighted,
            hasError,
            handleKeyUp,
        }
    });

    const chunkValues = chunkStates.map(({ value }) => value);

    // WHEN OUTSIDE VALUE CHANGES (UPDATE INTERNAL PART STATE)
    useEffect(
        () => {
            let value = preFormat(props.value);
            let splitValue = splitValueForLocalState(value);
            
            if (filter(value)) {
                chunkStates.map((chunk, index) => {
                    let incomingValue = splitValue[index];
                    let currentValue = chunk.value;
                    if (chunk.shouldUpdateLocalState(incomingValue, currentValue)) {
                        chunk.setValue(incomingValue)
                    }
                });
            }
        },
        [props.value]
    );

    // WHEN CHUNK VALUES CHANGE (UPDATE OUTSIDE STATE)
    useEffect(
        () => {
            if (allChunkValuesPassValidation()) {
                let finalValue = getCombinedFinalValue();
                let splitValue = chunkStates.map(({ value }) => value);
                props.onChange(finalValue, splitValue);
            } 
            
            if (!allChunkValuesPassValidation() && isPlain(props.onChangeToInvalid)) {
                props.onChangeToInvalid();
            }
        },
        chunkValues
    );

    const allChunkValuesPassValidation = () => pipe(
        chunkStates,
        allMatchPredicate(({ hasError, isFilled }) => isFilled() && !hasError())
    );

    const getCombinedFinalValue = () => {
        return chunkStates
            .map(({ value, onChangeFormat }) => onChangeFormat(value))
            .join("")
    }

    const splitValueForLocalState = (value: string): Array<string> =>
        pipe(
            props.chunkConfig,
            array.reduce(
                {
                    remainingValue: value.split(""),
                    valueInChunks: [] as Array<Array<string>>,
                },
                (sum, { max }) => {
                    let splitValue = array.splitAt(max)(sum.remainingValue);
                    return {
                        remainingValue: splitValue[1],
                        valueInChunks: sum.valueInChunks.concat([splitValue[0]])
                    }
                },
            ),
            (sum) => sum.valueInChunks, 
            array.map((chunkValue) => chunkValue.join("")),
            mergeOnTop(pipe(
                array.range(0, props.chunkConfig.length-1),
                array.map(() => "")
            ))
        )
    ;

    return chunkStates
}

const booleanFuncDud = (v: string) => true;