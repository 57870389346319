import { pipe } from "fp-ts/lib/pipeable";
import { stringToInt } from "./stringToInt";
import { option } from "fp-ts";

/**
 * Checks if a string representing an integer equals a given integer value
 * 
 * @example
 * isStringIntEqualToInt("123", 123) // returns true
 * isStringIntEqualToInt("01", 1) // returns true
 * isStringIntEqualToInt("123", 456) // returns false
 * isStringIntEqualToInt("abc", 123) // returns false
 */
export const isStringIntEqualToInt = (stringInt: string, int: number): boolean =>
    pipe(
        stringToInt(stringInt),
        option.map((parsedInt) => parsedInt === int),
        option.getOrElse(() => false)
    )
;
