import { TTypeOfCodec } from "../../shared/src/codecs/codec";
import { union } from "../../shared/src/codecs/types/union";
import { literal } from "../../shared/src/codecs/types/literal";
import { requireExhaustive } from "../../shared/src/util";
import { pipe } from "fp-ts/lib/function";
import { array, string } from "fp-ts";

export const UserCaseUserType = union([
    literal("unknown"),
    literal("client"),
    literal("authorised_third_party"),
    literal("registered_third_party"),
    literal("executor"),
    literal("deceased"),
    literal("beneficiary_receiving_payment"),
    literal("beneficiary_not_receiving_payment"),
    literal("giftor"),
    literal("company_executor"),
    literal("personal_representative"),
    literal("lpa"),
    literal("introducer"),
]);
export type TUserCaseUserType = TTypeOfCodec<typeof UserCaseUserType>;

const UserCaseUserTypeDisplayName = union([
    literal("Unknown"),
    literal("Client"),
    literal("Authorised third party"),
    literal("Registered third party"),
    literal("Executor"),
    literal("Deceased"),
    literal("Beneficiary receiving payment"),
    literal("Beneficiary not receiving payment"),
    literal("Giftor"),
    literal("Company executor"),
    literal("Personal representative"),
    literal("LPA"),
    literal("Introducer"),
]);
type TUserCaseUserTypeDisplayName = TTypeOfCodec<typeof UserCaseUserTypeDisplayName>;

export const UserCaseUserType_UserCaseUserTypeDisplayName = (userType: TUserCaseUserType): TUserCaseUserTypeDisplayName =>
    userType === "unknown" ? "Unknown"
    : userType === "client" ? "Client"
    : userType === "authorised_third_party" ? "Authorised third party"
    : userType === "registered_third_party" ? "Registered third party"
    : userType === "executor" ? "Executor"
    : userType === "deceased" ? "Deceased"
    : userType === "beneficiary_receiving_payment" ? "Beneficiary receiving payment"
    : userType === "beneficiary_not_receiving_payment" ? "Beneficiary not receiving payment"
    : userType === "giftor" ? "Giftor"
    : userType === "company_executor" ? "Company executor"
    : userType === "personal_representative" ? "Personal representative"
    : userType === "lpa" ? "LPA"
    : userType === "introducer" ? "Introducer"
    : requireExhaustive(userType);

export const HasBankAccountAccess: Array<TUserCaseUserType> = [
    "client",
    "authorised_third_party",
    "executor",
    "beneficiary_receiving_payment",
    "personal_representative",
];

export const CanBeBankAccountRecipient: Array<TUserCaseUserType> = [
    "client",
    "executor",
    "deceased",
    "beneficiary_receiving_payment",
    "personal_representative",
    "lpa",
];

export const RequiresIdCheck: Array<TUserCaseUserType> = [
    "client",
    "registered_third_party",
    "executor",
    "beneficiary_receiving_payment",
    "beneficiary_not_receiving_payment",
    "giftor",
    "personal_representative",
    "lpa",
];

export const RequiresVideoCallVerification: Array<TUserCaseUserType> = [
    "client",
    "registered_third_party",
    "executor",
    "beneficiary_receiving_payment",
    "personal_representative",
    "lpa",
];

export const RequiresBankruptcySearchPurchaseTransferOrRemortgage: Array<TUserCaseUserType> = [
    "client",
    "registered_third_party",
    "giftor",
];

export const RequiresBankruptcySearchSale: Array<TUserCaseUserType> = [
    "deceased",
];

export const RequiresBankruptcySearch: Array<TUserCaseUserType> = pipe(
    [
        ...RequiresBankruptcySearchPurchaseTransferOrRemortgage,
        ...RequiresBankruptcySearchSale,
    ],
    array.uniq<TUserCaseUserType>(string.Eq),
);

export const CanHelpOthersWithIdCheck: Array<TUserCaseUserType> = [
    "client",
    "authorised_third_party",
    "personal_representative",
];

export const CanSettlePayment: Array<TUserCaseUserType> = [
    "client",
    "authorised_third_party",
    "personal_representative",
    "executor",
];

export const CanConfirmCaseUsers: Array<TUserCaseUserType> = [
    "client",
    "authorised_third_party",
    "executor",
    "personal_representative",
    "lpa",
];

export const CanAddRelatedTransaction: Array<TUserCaseUserType> = [
    "client",
    "authorised_third_party",
    "executor",
    "personal_representative",
    "lpa",
];

export const CanAnswerPurchasePaymentDetailsQuestions: Array<TUserCaseUserType> = [
    "client",
    "authorised_third_party",
    "executor",
    "personal_representative",
    "lpa"
];

export const CanAnswerRemortgageMortgageDetailsQuestions: Array<TUserCaseUserType> = [
    "client",
    "authorised_third_party",
    "executor",
    "personal_representative",
    "lpa"
];

export const CanAnswerSDLTQuestions: Array<TUserCaseUserType> = [
    "client",
    "authorised_third_party",
    "personal_representative",
];

export const CanAnswerChargeAccountNumbersQuestions: Array<TUserCaseUserType> = [
    "client",
    "authorised_third_party",
    "personal_representative",
    "executor",
];

export const CanAgreeBSAAdvice: Array<TUserCaseUserType> = [
    "client",
    "authorised_third_party",
    "executor",
    "personal_representative",
    "lpa"
];

export const RequiresDualRepConsent: Array<TUserCaseUserType> = [
    "client",
    "personal_representative",
    "lpa",
    "executor",
];

export const CanAnswerPropertyInformationForms: Array<TUserCaseUserType> = [
    "client",
    "authorised_third_party",
    "personal_representative",
    "executor",
];

export const CanInstructCase: Array<TUserCaseUserType> = [
    "client",
    "executor",
];

export const RequiredToAnswerJointOwnershipHeldAsQuestion: Array<TUserCaseUserType> = [
    "client",
    "executor",
];

export const RequiredToAnswerDeclarationOfTrustReferralQuestion: Array<TUserCaseUserType> = [
    "client",
    "executor",
    "giftor",
];

export const RequiredToAnswerWillReferralQuestion: Array<TUserCaseUserType> = [
    "client",
    "executor",
    "giftor",
    "beneficiary_not_receiving_payment",
    "beneficiary_receiving_payment",
    "lpa",
    "personal_representative",
    "registered_third_party",
];

export const CanAccessPortal: Array<TUserCaseUserType> = pipe(
    [
        ...HasBankAccountAccess,
        ...RequiresIdCheck,
        ...CanHelpOthersWithIdCheck,
        ...CanSettlePayment,
        ...CanConfirmCaseUsers,
        ...CanAddRelatedTransaction,
        ...CanAnswerPurchasePaymentDetailsQuestions,
        ...CanAnswerSDLTQuestions,
        ...CanAnswerChargeAccountNumbersQuestions,
        ...CanInstructCase,
        ...RequiredToAnswerJointOwnershipHeldAsQuestion,
        ...RequiredToAnswerDeclarationOfTrustReferralQuestion,
        ...RequiredToAnswerWillReferralQuestion,
    ],
    array.uniq<TUserCaseUserType>(string.Eq),
);
