/**
 * Checks if a string is a valid ISO 8601 date format
 * 
 * Valid formats include: `YYYY-MM-DD`, and `YYYY-MM-DDThh:mm:ss.sssZ`
 */
export function isISODate(value: string): boolean {

    if (isNaN((new Date(value)).getTime())) {
        return false;
    }
    
    const ISODateRegex = /^\d{4}-\d{2}-\d{2}(T\d{2}:\d{2}:\d{2}(\.\d{1,3})?(Z|[+-]\d{2}:?\d{2})?)?$/;
    return ISODateRegex.test(value);
}
