import { array } from "fp-ts";
import { pipe } from "fp-ts/lib/pipeable";

export const allMatchPredicate = <V extends unknown>(predicate: (value: V) => boolean) =>
    (value: Array<V>): boolean => {
        if (value.length === 0) {
            return false;
        }
        return pipe(
            value,
            array.filter((member) => !predicate(member)),
            (filteredArray) => filteredArray.length === 0
        )
    }
;