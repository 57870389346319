import React, { useState } from "react";
import { TContainerStateProps } from "../../state/TContainerStateProps";
import { CRMMenuBar, TMenuBarButton } from "../../components/Complex/CRMMenuBar/CRMMenuBar";
import { CRMColors } from "../../models/CRMColors";
import { useOpenClose } from "../../hooks/UseOpenClose";
import { CRMCaseSearchOverlay } from "../../components/Complex/CRMCaseSearchOverlay/CRMCaseSearchOverlay";
import { useEffect } from "react";
import { CRMComposeEmailForm } from "../../components/CRM/CRMComposeEmail/CRMComposeEmailForm/CRMComposeEmailForm";
import { CRMCardOutsidePopupBlank } from "../../components/CRMCardOutsidePopupBlank/CRMCardOutsidePopupBlank";
import { HiddenScroll } from "../../components/BuildingBlocks/HiddenScroll";
import { CRMBlockFilterCheckboxes } from "../../components/Simple/CRMBlockFilterCheckboxes/CRMBlockFilterCheckboxes";
import { FeatureToggle } from "../../../../domain/codecs/FeatureToggle";
import { ZIndex } from "../../components/BuildingBlocks/ZIndex";
import { CRMZIndex } from "../../models/CRMZIndex";
import { isLocal, isStaging } from "../../functions/featureFlag";
import { CRMKPIs } from "../../components/Simple/CRMKPIs/CRMKPIs";

export const CRMLegalMenuBarContainer = (props: TContainerStateProps): JSX.Element => {
    const [kpisIsOpen, setKPISIsOpen] = useState<boolean>(false);
    const searchCasesOverlay = useOpenClose();
    const composeEmaiPopup = useOpenClose();

    const listenToKeyboardShortcutForSearchCasesOverlayToggle = (event: KeyboardEvent) => {

        if (event.ctrlKey === true && event.code === "Space") {
            toggleCaseSearchOverlay();
        }
    }

    useEffect(() => {
        window.addEventListener("keydown", listenToKeyboardShortcutForSearchCasesOverlayToggle);
        return () => window.removeEventListener("keydown", listenToKeyboardShortcutForSearchCasesOverlayToggle);
    })

    const isSessionUserAdmin = (): boolean => props.state.global.user.user_role === "admin";
    
    const isSessionUserAuthoriser = (): boolean => props.state.global.user.user_role === "sail_legal_authoriser_staff";
    
    const isSessionUserAuthoriserOrAdmin = (): boolean => isSessionUserAdmin() || isSessionUserAuthoriser();

    const closeCaseSearchOverlay = () => {
        searchCasesOverlay.setIsOpen(false);
        resetCaseSearchQuery();
    }
    
    const toggleCaseSearchOverlay = () => {
        searchCasesOverlay.toggleOpen();
        resetCaseSearchQuery();
    }

    const resetCaseSearchQuery = (): void => props.dispatch({
        type: "CRM_TYPE_CASE_SEARCH_TEXT",
        payload: "",
    });

    return (
        <div>
            <CRMMenuBar
                logoHref="/crm/legal/cases"
                colors={{
                    backgroundColor: CRMColors.HIGHLIGHTS_POSITIVE_GREEN_0,
                    logoBackgroundColor: CRMColors.HIGHLIGHTS_POSITIVE_GREEN_2,
                    logoIconColor: "highlights-positive-green-12",
                    hoverColor: CRMColors.HIGHLIGHTS_POSITIVE_GREEN_4,
                }}
                leftButtons={[
                    ...[
                        {
                            icon: "send-email" as const,
                            label: "Compose email",
                            onClick: () => props.dispatch({
                                type: "CRM_OPEN_COMPOSE_EMAIL",
                                payload: null
                            })
                        },
                        {
                            icon: "email-notification" as const,
                            label: "",
                            href: `/crm/legal/inbox`,
                            badges: [
                                {
                                    key: "confidential",
                                    count: props.state.forms.inbox_count_form.children.confidential,
                                    spreadLabel: "Confidential",
                                    backgroundColour: CRMColors.HIGHLIGHTS_CALMING_PINK_2,
                                    fontColour: CRMColors.NEUTRAL_PAPER,
                                },
                                {
                                    key: "unresolved",
                                    count: props.state.forms.inbox_count_form.children.unresolved,
                                    spreadLabel: "Unresolved",
                                    backgroundColour: CRMColors.HIGHLIGHTS_INSTRUCTIONAL_BRICK_0,
                                    fontColour: CRMColors.NEUTRAL_PAPER,
                                },
                            ]
                        },
                        {
                            icon: "phone" as const,
                            label: "Twilio",
                            href: "https://flex.twilio.com",
                            openInNewTab: true,
                        },
                        {
                            icon: "new-box" as const,
                            label: "New Case",
                            onClick: () => props.dispatch({type: "CRM_ADD_NEW_CASE_BUTTON_CLICKED"})
                        },
                        {
                            icon: "report" as const,
                            label: "Reports",
                            href: `/crm/legal/reports`,
                            isDisabled: !isSessionUserAuthoriserOrAdmin()
                        },
                        {
                            icon: "introducer" as const,
                            label: "Introducers",
                            href: "/crm/legal/introducers",
                        },
                        {
                            icon: "campaign" as const,
                            label: "Marketing Campaigns",
                            href: "/crm/legal/marketing-campaigns",
                        },
                        ...(env.REACT_APP_ACCOUNT_NAME !== "propertybid-production" ? [{
                            icon: "handshake" as const,
                            label: "Company Partners",
                            href: "/crm/legal/companies/partners",
                        }] as Array<TMenuBarButton> : []),
                        ...(
                            isSessionUserAdmin()
                                ? [{
                                    icon: "quote" as const,
                                    label: "Quote Rates",
                                    href: "/crm/legal/quote-rates",
                                }] as Array<TMenuBarButton>
                                : []
                        ),
                    ],
                    ...sessionUser.user_role === "admin" ? [{
                        icon: "triangle" as const,
                        label: "Triage",
                        href: "/crm/legal/triage",
                    }] : [],
                ]}
                centerButtons={[
                    {
                        icon: "search-list",
                        label: "Search Cases",
                        onClick: toggleCaseSearchOverlay
                    },
                ]}
                rightButtons={[
                    {
                        icon: "speed",
                        label: "KPIs",
                        onClick: () => setKPISIsOpen(true)
                    },
                    ...(
                        isStaging
                        || isLocal
                            ? [<ZIndex zIndex={CRMZIndex.SECOND_Z}>
                                <CRMBlockFilterCheckboxes
                                    heading="Feature Toggles"
                                    backgroundColour="transparent"
                                    popoutPosition="bottom"
                                    icon="flag"
                                    displayBadgeCount={true}
                                    iconColour={CRMColors.NEUTRAL_PAPER}
                                    borderLeftColour="none"
                                    options={FeatureToggle.values}
                                    optionsSelected={props.state.forms.session_feature_toggle_form.edited.enabled_feature_toggles}
                                    onSelectedChange={(enabled_feature_toggles) => props.dispatch({
                                        type: "CRM_SESSION_FEATURE_TOGGLE_CHANGE",
                                        payload: {
                                            ...props.state.forms.session_feature_toggle_form,
                                            edited: {
                                                enabled_feature_toggles,
                                            }
                                        }
                                    })}
                                />
                            </ZIndex>]
                            : []
                    ),
                    {
                        icon: "settings",
                        label: "Settings",
                        href: "/crm/admin",
                        isDisabled: !isSessionUserAdmin()
                    },
                    {
                        icon: "logout",
                        label: "Logout",
                        href: `${env.REACT_APP_AUTH_URL}/web/logout`,
                        onClick: (e) => {
                            if (confirm("Confirm logout") !== true) {
                                e.preventDefault();
                            }
                        }
                    },
                ]}
            />

            {/* SEARCH FOR CASES OVERLAY */}
            <CRMCaseSearchOverlay
                isOpen={searchCasesOverlay.isOpen}
                onClose={closeCaseSearchOverlay}
                searchValue={props.state.forms.global_legal.cases_search.edited.searchString}
                cases={props.state.forms.global_legal.cases_search.children}
                onChange={(searchString) => props.dispatch({
                    type: "CRM_TYPE_CASE_SEARCH_TEXT",
                    payload: searchString,
                })}
            />

            {/* SEND EMAIL POPUP */}
            <CRMCardOutsidePopupBlank
                isOpen={props.state.forms.global_legal.compose_email_is_open}
                onClose={() => composeEmaiPopup.setIsOpen(false)}
                context="none"
                size={"medium"}
                noCloseOnShadowboxClick={true}
            >
                <HiddenScroll
                    width="600px"
                    height="745px"
                >
                    <CRMComposeEmailForm
                        apiUrl={env.REACT_APP_API_URL} 
                        composingUser={sessionUser}
                        form={props.state.forms.global_legal.compose_email}
                        closeMode="close-and-clear"
                        attachedEmail={props.state.forms.global_legal.attached_email}
                        additionalSendToEmailAddresses={props.state.forms.global_legal.additional_compose_email_addresses}
                        onChangeSearchCaseValue={(payload) => props.dispatch({
                            type: "CRM_COMPOSE_EMAIL_TYPE_CASE_SEARCH_TEXT",
                            payload,
                        })}
                        onSelectCase={(payload) => props.dispatch({
                            type: "CRM_SET_CASE_FOR_COMPOSE_EMAIL_FORM",
                            payload,
                        })}
                        onChange={(payload) => props.dispatch({
                            type: "CRM_UPDATE_COMPOSE_EMAIL_FORM",
                            payload,
                        })}
                        onSubmit={(payload) => props.dispatch({
                            type: "CRM_SEND_NEW_EMAIL",
                            payload,
                        })}
                        onReply={(payload) => props.dispatch({
                            type: "CRM_SEND_REPLY_EMAIL",
                            payload,
                        })}
                        onForward={(payload) => props.dispatch({
                            type: "CRM_SEND_FORWARD_EMAIL",
                            payload,
                        })}
                        onClear={() => props.dispatch({
                            type: "CRM_RESET_COMPOSE_EMAIL_FORM",
                            payload: null,
                        })}
                        onClose={() => props.dispatch({
                            type: "CRM_CLOSE_COMPOSE_EMAIL",
                            payload: null,
                        })}
                    />
                </HiddenScroll>
            </CRMCardOutsidePopupBlank>

            {/* KPIS */}
            <CRMKPIs
                isOpen={kpisIsOpen}
                onClose={() => setKPISIsOpen(false)}
            />
        </div>
    );
};
