import { none, some } from "fp-ts/lib/Option";
import { hasLetters } from "./hasLetters";

/**
 * Converts a string to an integer, returning an Option type.
 * @example
 * stringToInt("123") // some(123)
 * stringToInt("abc") // none
 */
export const stringToInt = (value: string) => {
    const number = parseInt(value);
    if (isNaN(number) || hasLetters(value)) {
        return none;
    }
    return some(number);
}