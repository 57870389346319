import React from "react";
import { TChunkState } from "../../../../hooks/useSplitInputV2";

type TDatePartProps = {
    chunk: TChunkState;
    placeholder?: string;
    hasGlobalError?: boolean;
    isDisabled?: boolean;
    isHighlighted?: boolean;
    part: "day" | "month" | "year";
};


/**
* An input which is to be used for each **date unit** (day, month, year) of `InputDateText`.
* * Handles all the styling and behaviour hook-up that is given to it by `useSplitInput`.
* * Styles itself according to the position (day, month, year) in the date input it is in.
**/
export const DatePart = (props: TDatePartProps): JSX.Element => {

    const value = props.chunk.value;
    const hasChunkError = props.chunk.hasError() && value !== "0";
    const isHighlighted = props.isHighlighted || props.chunk.isHighlighted();
    // We want the internal logic to treat a 0 value as an erroneous value;
    // but we want the input to not go red as you are typing a leading 0 value, for sake of smoothness.
    const showError = hasChunkError || props.hasGlobalError

    return (
        <input
            ref={props.chunk.ref}
            className={`
                front-date-of-birth-input__input
                front-date-of-birth-input__input--${props.part}
                front-date-of-birth-input__input--${props.part}-${showError ? "error" : "no-error"}
                front-date-of-birth-input__input--${props.isDisabled ? "disabled" : "enabled"}
                front-date-of-birth-input__input--${isHighlighted ? "highlighted" : "not-highlighted"}-${showError ? "error" : "plain"}
            `}

            disabled={props.isDisabled}
            placeholder={props.placeholder}
            
            type={"text"}
            value={value}
            onChange={(event) => {
                props.chunk.setValue(event.target.value)
            }}
            onPaste={(event) => event.preventDefault()}
            onKeyUp={props.chunk.handleKeyUp}
            maxLength={props.chunk.maxLength}
            pattern="\d*"
            inputMode="numeric"
            autoComplete="off"
        />
    )
}