import React from "react";

import { TChunkState, useSplitInputV2 } from "../../../../hooks/useSplitInputV2";
import { addLeadingZeroToStringInt } from "../../../../../../shared/src/utilsByDomain/string/addLeadingZeroToStringInt";
import { DatePart } from "./DatePart";
import { 
    getFormattedValue, 
    isAcceptableDateInput, 
    isDayInputValid, 
    isDateNumberStringUnder, 
    isYearInputValid,
    formatMonthOrYear,
    shouldUpdateDatePart
} from "./functions";


export type TInputDateTextProps = 
    {
        value: string | null;
        onChange: (value: string | null) => void;
        isDisabled?: boolean;
        hasError?: boolean;
    }
;

// # Expected Behavior
// [KEYBOARD BEHAVIOR]
// 1) When you type in a field that is full: you are focused to the end of the next one.
// 2) When you backspace on an empty field: you are focused to the end of the previous one.
// 3) When you press left arrow and you are at the start of a input value you are moved to the beginning of the previous input (if there is one).
// 4) When you press right arrow and you are at the end of a input value you are moved to the start of the next input (if there is one)
// 5) When you tab and it selects all and you backspace it should not go to the previous input yet.
// 6) When you start at the beginning of an input that has text, you next character overwrites everything in it.
//
// [GENERAL]
// 1) You can type letters, only numbers
// 2) No pasting is allowed.
// 3) You are allowed to type single numbers for days and months.
// 4) You get a validation error if a day is to high for a month.
// 5) When a day is set and the month is 0 the day should not show an error yet
// 6) When you set a value to 0 that split input should not be counted as valid
// 7) A year with a leading zero should be invalid
//
// [VALUE]
// 5) Anytime the local state is invalid, the global state is set to null (onChange(null))
// 6) When the value is correct it is formatted to an UTC ISO.
// 7) When a ISO value is given if it's using single digit days and month its not updated.
// 8) The value taken can only be a ISO date or null.
//
// [VALID INPUT EXAMPLES]
// We need to support all of these being typed
// 1) 1 02 1993
// 2) 1 2 1993
// 3) 02 02 1993
export const FrontDateOfBirthInput = (props: TInputDateTextProps): JSX.Element => {

    const [dayState, monthState, yearState] = useSplitInputV2({
        value: props.value || "",
        // We format props.value to a ddmmyyyy format so that it is easy to chunk. 
        // If it's not a valid ISO date its an empty string
        preFormat: getFormattedValue,
        // This runs when props.value changes and has been formatted.
        // * Because we want to set props.value to null whenever the date is invalid
        //   we have to filter out empty strings because they would set the internal chunk state
        //   every time onChangeToInvalid was called (which is most of the time)
        //   this would break the experience.
        filter: (value) => value.length > 0,
        shouldMoveToNextInput: (key) => key === "/",
        chunkConfig: [
            {
                max: 2,
                min: 1,
                validation: isDayInputValid,
                filter: isAcceptableDateInput,
                onChangeFormat: addLeadingZeroToStringInt,
                shouldUpdateLocalState: shouldUpdateDatePart
            },
            { 
                max: 2,
                min: 1,
                validation: isDateNumberStringUnder(13),
                filter: isAcceptableDateInput,
                onChangeFormat: formatMonthOrYear,
                shouldUpdateLocalState: shouldUpdateDatePart
            },
            { 
                max: 4, 
                validation: isYearInputValid,
                filter: isAcceptableDateInput,
                onChangeFormat: formatMonthOrYear
            },
        ],
        onChange: (value) => {
            // We set the timezone manually to avoid dateTime library bugs.
            let [day, month, year] = value.split("-");
            let ISODate = `${year}-${month}-${day}T00:00:00.000Z`;
            props.onChange(ISODate);
        },
        onChangeToInvalid: () => {
            props.onChange(null);
        }
    });

    const isDayFull = dayState.value.length > 0;
    const isMonthFull = monthState.value.length > 0;
    const isYearFull = yearState.value.length === 4;
    const isPartEmpty = (part: TChunkState) => part.value.length === 0;
    
    return (
        <div className={`front-date-of-birth-input`}>
            <DatePart
                part="day"
                placeholder="DD"
                chunk={dayState}
                hasGlobalError={props.hasError}
                isDisabled={props.isDisabled}
                isHighlighted={isPartEmpty(dayState) && isMonthFull && isYearFull}
            />
            
            <Slash 
                hasError={props.hasError || dayState.hasError() || monthState.hasError()}
                isDisabled={props.isDisabled}
            />

            <DatePart
                part="month"
                placeholder="MM"
                chunk={monthState}
                hasGlobalError={props.hasError}
                isDisabled={props.isDisabled}
                isHighlighted={isPartEmpty(monthState) && isDayFull && isYearFull}
            />
            
            <Slash
                hasError={props.hasError || yearState.hasError() || monthState.hasError()}
                isDisabled={props.isDisabled}
            />

            <DatePart
                part="year"
                placeholder="YYYY"
                chunk={yearState}
                hasGlobalError={props.hasError}
                isDisabled={props.isDisabled}
                isHighlighted={isPartEmpty(yearState) && isDayFull && isMonthFull}
            />
        </div>
    );
};

type SlashProps = {
    hasError: boolean;
    isDisabled?: boolean;
}

const Slash = ({ hasError, isDisabled }: SlashProps) => {
    return (
        <div 
            className={`
                front-date-of-birth-input__slash 
                front-date-of-birth-input__slash--${hasError ? "error" : "no-error"}
                front-date-of-birth-input__slash--${isDisabled ? "disabled" : "enabled"}
            `}
        >/</div>
    )
}