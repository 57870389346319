import { option } from "fp-ts";
import { pipe } from "fp-ts/lib/pipeable";
import { isISODate } from "./../../../../../../shared/src/utilsByDomain/dateTime/isISODate";
import { ISODateToPlainDate } from "./../../../../../../shared/src/utilsByDomain/dateTime/ISODateToPlainDate";
import { isIntUnder } from "./../../../../../../shared/src/utilsByDomain/interger/isIntUnder";
import { stringToInt } from "./../../../../../../shared/src/utilsByDomain/string/stringToInt";
import { hasOnlyNumbers } from "./../../../../../../shared/src/utilsByDomain/string/hasOnlyNumbers";
import { addLeadingZeroToStringInt } from "./../../../../../../shared/src/utilsByDomain/string/addLeadingZeroToStringInt";
import { isStringIntEqualToInt } from "./../../../../../../shared/src/utilsByDomain/string/isStringIntEqualToInt";

export const daysInMonths = {
    "1": 31,
    "2": 29,
    "3": 31,
    "4": 30,
    "5": 31,
    "6": 30,
    "7": 31,
    "8": 31,
    "9": 30,
    "10": 31,
    "11": 30,
    "12": 31,
};

export const getFormattedValue = (value: string) => isISODate(value) ? ISODateToPlainDate(value) : "";

export const isDateNumberStringUnder = (max: number) => (value: string) => {
    return pipe(
        value,
        stringToInt,
        option.map((int) => 
            int === 0 ? false 
            : isIntUnder(max)(int)
        ),
        option.getOrElse(() => false)
    )
}

export const isAcceptableDateInput = (value: string): boolean => {
    return value.length === 0 || hasOnlyNumbers(value);
};

export const isDayInputValid = (dayValue: string, chunkValues: Array<string>): boolean => {
    let [_, monthValue] = chunkValues;

    return pipe(
        stringToInt(monthValue),
        option.map((monthNumber) =>
            // @ts-ignore | TODO[C] look into casting this properly if it's necessary
            monthNumber === 0 ? 32 : daysInMonths[`${monthNumber}`] + 1
        ),
        option.getOrElse(() => 32),
        (number) => isDateNumberStringUnder(number)(dayValue),
    );
}

export const isYearInputValid = (yearValue: string) => {
    
    let currentYear = (new Date()).getFullYear();

    return pipe(
        stringToInt(yearValue),
        option.map((int: number) => 
            int <= 1900 ? false 
            : isIntUnder(currentYear+1)(int)
        ),
        option.getOrElse(() => false)    
    );
}

export const formatMonthOrYear = (value: string) => `-${addLeadingZeroToStringInt(value)}`;

export const shouldUpdateDatePart = (incomingValue: string, currentValue: string) => pipe(
    stringToInt(currentValue),
    option.map((value) => !isStringIntEqualToInt(incomingValue, value)),
    option.getOrElse(() => true)
);