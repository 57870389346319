import { pipe } from "fp-ts/lib/pipeable";
import { stringToInt } from "./stringToInt";
import { option } from "fp-ts";

/**
 * Adds a leading zero to a string integer if it's the int is between 1 and 9.
 * @example
 * addLeadingZeroToStringInt("5") // returns "05"
 * addLeadingZeroToStringInt("10") // returns "10"
 * addLeadingZeroToStringInt("abc") // returns "abc"
 */

export const addLeadingZeroToStringInt = (value: string): string => {
    return pipe(
        stringToInt(value),
        option.map((int) => 
            int > 0 && int < 10 && value[0] !== "0" ? 
                `0${value}` 
                : value
        ),
        option.getOrElse(() => value)
    );
};
